<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">

    <div v-if="!isSearchAreaShow" class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        APP PUSH 알림
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-search">
          <a href="javascript:" @click="isSearchAreaShow = true" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>

    <div v-else class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" @click="isSearchAreaShow = false" class="util-actions util-actions-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
        <div class="util util-search-input">
          <input type="text" v-model="search.postTitle" placeholder="APP PUSH 알림 검색">
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-search">
          <a href="javascript:" @click="clickSearch" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-helpdesk">
    <!-- main-content -->
    <div class="main-content main-component">

      <div v-if="items.length === 0 && searchRslt > 0" class="search-result">
        <!-- result-empty -->
        <div class="result-empty">
          <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
          <p class="text">검색결과가 없어요</p>
        </div>
        <!-- //result-empty -->
      </div>

      <div v-else class="notice-list-container">
        <div class="list-top status">
          <div class="top-column">
            <p class="text text-muted">총 {{ paging.totalCount }}건 (최근 3개월)</p>
          </div>
        </div>
        <div class="board-list-container">
          <ul class="board-list">
            <li class="board-list-item" v-for="(item, idx) in items" :key="idx">
              <div class="list-content">
                <div class="list-content-title">
                  <h5 class="title">
                    <span class="text text-truncate">{{item.notiTitle}}</span>
                  </h5>
                </div>
                <div class="view-body">
                  <div class="view-content" style="font-family: KBFGDisplay; line-height: 20px;" v-html="item.notiCn">
                  </div>
                </div>
                <div class="list-meta-content">
                  <span class="text">{{timestampToDateFormat(item.notiTrsmCmptnDt, 'yyyy.MM.dd hh:mm')}}</span>
<!--                  <span class="text">{{item.inqCnt}} 조회</span>-->
                </div>
              </div>
<!--              <div class="board-column column-file" v-if="item.postFileAtchYn === 'Y'"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>-->
            </li>
          </ul>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>

</template>

<script>

import {onBeforeMount, onMounted, reactive, ref, watch, computed} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  getItems,
  getPaging,
  initPaging,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat,
  getText,
} from '@/assets/js/util';
import {ACT_GET_NOTI_TRSM_APPPUSH_LIST} from "@/store/modules/notification/notification";
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {isInAppAnd} from "@/assets/js/navigationUtils";


export default {
  name: 'TrainMobileNotification',
  components: {CommonPaginationFront},
  setup(){
    const isCategoryActive = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([]);

    const search = reactive({
      postTitle: ''
    });

    const headerOption = ref({
      showBack: false,
      showBreadcrumb: true,
      showTitle: true,
      isSearch: false
    });

    const isSearchAreaShow = ref(false);
    const searchRslt = ref(0);

    // 목록, 검색페이지 이동시 데이터 세팅
    watch(() => isSearchAreaShow.value, () => {
      if(isSearchAreaShow.value){
        items.value = [];
        paging.value.totalCount = 0;
      }else{
        search.postTitle = '';
        searchRslt.value = 0;
        if(Object.keys(route.query).length === 0) getTrainNotiApppushList();
        else pagingFunc();
      }
    })

    const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));
    const getTrainNotiApppushList = () => {
      store.dispatch(`notification/${ACT_GET_NOTI_TRSM_APPPUSH_LIST}`, {
        notiTitle: search.postTitle,
        notiAppTyCdDcd: isInAppAnd ? '2035003' : '2035004',
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          // 새 게시물인지 날짜로 비교
          items.value.map((x) => {
            let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.notiTrsmCmptnDt, 'yyyy-MM-dd'));
            let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
            let date = new Date(diff).getDate();
            if(month + date <= 7) x.newLib = 'Y';
            else x.newLib = 'N';

            x.notiCn = x.notiCn.replaceAll("\n", "<br>");
          })
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.postTitle) query.postTitle = search.postTitle;
      router.push({query: query});
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const clickSearch = () => {
      headerOption.value.showBack = true;
      headerOption.value.showBreadcrumb = false;
      headerOption.value.showTitle = false;
      headerOption.value.isSearch = true;
      searchRslt.value++;

      pagingFunc(1, 10);
    };

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'TrainNotification', paging, search, true);
      if (!route.query.postTitle) {
        headerOption.value.showBack = false;
        headerOption.value.showBreadcrumb = true;
        headerOption.value.showTitle = true;
        headerOption.value.isSearch = false;
      }
      getTrainNotiApppushList();
    });

    onBeforeMount(() => {
      // CommonPagination 컴포넌트가 로드되기 전에 paging을 고쳐야 query string에 있는 pageSize가 셀렉트 박스에 반영됨.
      setParamsByQueryString(route, 'TrainNotification', paging, search, true); // 새로고침을 위한 작업
    });

    onMounted(() => {
      getTrainNotiApppushList();
    });

    return{
      isCategoryActive,
      items,
      search,
      paging,
      isSearchAreaShow,
      headerOption,
      searchRslt,
      pagingFunc,
      clickSearch,
      getDateFormat,
      timestampToDateFormat,
      getText,

    }

  }
}
</script>